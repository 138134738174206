/* Styling for the entire page */
body {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Wheel Container */
  .wheel-container {
    position: relative;
    text-align: center;
  }
  
  /* Pointer Styling */
  .pointer {
    position: absolute;
    top: -50px; /* Positioned above the wheel */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #fff;
    z-index: 10;
  }
  
  /* Wheel Styling */
  .wheel {
    border: 12px solid #444;
    border-radius: 50%;
    height: 400px; /* Wheel size */
    width: 400px; /* Wheel size */
    position: relative;
    margin: 0 auto;
    background: radial-gradient(circle at 50% 50%, #333 40%, #111 100%);
    box-shadow: 0 0 25px rgba(255, 0, 0, 0.7); /* Shadow effect */
    transition: transform 3s ease-out;
    transform-origin: center center;
  }
  
  /* Wheel Segment Styling */
  .wheel-segment {
    position: absolute;
    width: 50%;
    height: 50%;
    overflow: hidden;
    transform-origin: 100% 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Text Styling */
  .wheel-segment span {
    position: absolute;
    width: 200%;
    height: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: rotate(-45deg);
    transform-origin: center;
  }
  
  /* Segment Colors */
  .segment-0 { background: #ff0000; } /* Red */
  .segment-1 { background: #00ff00; } /* Green */
  .segment-2 { background: #0000ff; } /* Blue */
  .segment-3 { background: #ff00ff; } /* Pink */
  .segment-4 { background: #ffff00; } /* Yellow */
  .segment-5 { background: #800080; } /* Purple */
  .segment-6 { background: #ff4500; } /* Orange */
  .segment-7 { background: #008000; } /* Dark Green */
  
  /* Button Styling */
  button {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    background-color: #f00; /* Red button */
    color: #fff;
    border: 2px solid #f00;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: #fff;
    color: #f00;
  }
  
  /* Result Styling */
  .result {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #ff0; /* Yellow text */
  }
  
  
  
  
  
  
  
  
  
   
  
  
  
  
  
  
  
  