.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input {
    margin: 10px;
    padding: 5px;
    font-size: 1rem;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #f00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #d00;
  }
  
  .result {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #0a0;
  }
  