


body {
  margin: 0;
  font-family: 'Creepster', cursive;
  background: url('./assets/Ghost.jpg') repeat; /* Background image URL */
  background-size: cover; /* Cover the entire viewport */
  color: #f5f5f5;
  animation: moveBackground 50s linear infinite; /* Animate the background position */
 
}




@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}



@media only screen and (max-width: 600px) {
  body {
    h1 {
      font-size: 3px;
    }
  }
}


.typewriter {
  font-size: 25px;
  color: rgb(0, 0, 0); /* Text color */
  overflow: hidden; /* Ensures text does not overflow the container */
  white-space: nowrap; /* Keeps the text on one line */
  border-right: 0.15em solid #f00; /* Cursor effect */
  animation: typing 4s steps(40, end) 1s forwards, blink-caret 0.5s step-end infinite;
  width: 0; /* Start width at 0 and expand with animation */
}

/* Keyframes for typing effect */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Keyframes for caret blinking */
@keyframes blink-caret {
  0% { border-color: transparent; }
}

/* Container to hold the typewriter effect */
.container {
  display: inline-block;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #f00, 0 0 10px #f00, 0 0 15px #f00, 0 0 20px #f00, 0 0 25px #f00, 0 0 30px #f00, 0 0 35px #f00;
  }
  100% {
    text-shadow: 0 0 10px #f00, 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00;
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.6);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.6);
  }
}


.spooky-image {
  width: 150px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
  border: 8px solid #f00; /* Optional: add a border for effect */
  border-radius: 10px; /* Optional: rounded corners for the image */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.6); /* Initial glowing effect */
  animation: pulsate 2s ease-in-out infinite; /* Pulsating glow animation */
}

.spooky-image:hover {
  box-shadow: 0 0 30px rgba(255, 0, 0, 1); /* Glowing effect on hover */
}

h1 {
  font-size: 3rem;
  color: rgb(0, 0, 0); /* Text color */
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 10px rgba(255, 0, 0, 0.6), 0 0 15px rgba(255, 0, 0, 0.4), 0 0 20px rgba(255, 0, 0, 0.2);
  animation: glowAnimation 1.5s ease-in-out infinite; /* Apply animation */
}

@keyframes glowAnimation {
  0%, 100% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.8), 0 0 10px rgba(255, 0, 0, 0.6), 0 0 15px rgba(255, 0, 0, 0.4), 0 0 20px rgba(255, 0, 0, 0.2);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 1), 0 0 20px rgba(255, 0, 0, 0.8), 0 0 30px rgba(255, 0, 0, 0.6), 0 0 40px rgba(255, 0, 0, 0.4);
  }
}

form {
  background: #000000;
  padding: 66px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

label {
  display: block;
  margin-bottom: 30px;
  font-size: 1.5rem;
  margin-top: 15px;
}

input {
  padding: 10px;
  font-size: 1.2rem;
  border: 2px solid #666;
  border-radius: 5px;
  background: #222;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background: #c00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #f00;
}



nav {
  margin-bottom: 20px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: #f00;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}